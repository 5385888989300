<template>
    <div class="flex date-range-picker">
        <button class="btn btn-left" :disabled="$wait.is('loading_date_range.prev')" @click="previousMonth">
            <fa-icon v-if="$wait.is('loading_date_range.prev')" :icon="['fad', 'spinner']" spin class="text-xl mr-3" />
            <fa-icon v-else :icon="['far', 'angle-left']" class="text-xl mr-3" />
        </button>

        <button class="btn btn-right" :disabled="$wait.is('loading_date_range.next')" @click="nextMonth">
            <fa-icon v-if="$wait.is('loading_date_range.next')" :icon="['fad', 'spinner']" spin class="text-xl mr-3" />
            <fa-icon v-else :icon="['far', 'angle-right']" class="text-xl mr-3" />
        </button>

        <el-date-picker
            v-model="dateRange"
            type="daterange"
            :clearable="false"
            align="right"
            :editable="false"
            :picker-options="pickerOptions"
            @change="dateRangePicked"
        />
    </div>
</template>
<script>
import Vue from 'vue';
import { DatePicker } from 'element-ui';

import queryString from 'query-string';

Vue.use(DatePicker);

export default {
    props: {
        subtractedMonths: {
            type:    Number,
            default: 0,
        },
    },

    data() {
        return {
            dateRange:     [],
            pickerOptions: {
                firstDayOfWeek: 1,
                shortcuts:      [
                    {
                        text:    this.$t('common.january'),
                        onClick: picker => this.emitEvent(picker, 0),
                    },
                    {
                        text:    this.$t('common.february'),
                        onClick: picker => this.emitEvent(picker, 1),
                    },
                    {
                        text:    this.$t('common.march'),
                        onClick: picker => this.emitEvent(picker, 2),
                    },
                    {
                        text:    this.$t('common.april'),
                        onClick: picker => this.emitEvent(picker, 3),
                    },
                    {
                        text:    this.$t('common.may'),
                        onClick: picker => this.emitEvent(picker, 4),
                    },
                    {
                        text:    this.$t('common.june'),
                        onClick: picker => this.emitEvent(picker, 5),
                    },
                    {
                        text:    this.$t('common.july'),
                        onClick: picker => this.emitEvent(picker, 6),
                    },
                    {
                        text:    this.$t('common.august'),
                        onClick: picker => this.emitEvent(picker, 7),
                    },
                    {
                        text:    this.$t('common.september'),
                        onClick: picker => this.emitEvent(picker, 8),
                    },
                    {
                        text:    this.$t('common.october'),
                        onClick: picker => this.emitEvent(picker, 9),
                    },
                    {
                        text:    this.$t('common.november'),
                        onClick: picker => this.emitEvent(picker, 10),
                    },
                    {
                        text:    this.$t('common.december'),
                        onClick: picker => this.emitEvent(picker, 11),
                    },
                ],
            },
        };
    },

    created() {
        const query = queryString.parse(window.location.search, { sort: false });
        this.dateRange[0] = query.from ? this.$dayjs.unix(query.from) : this.$dayjs().subtract(this.subtractedMonths, 'months').startOf('month');
        this.dateRange[1] = query.to ? this.$dayjs.unix(query.to) : this.$dayjs().endOf('month');
    },

    methods: {
        dateRangePicked(event) {
            const query = queryString.parse(window.location.search, { sort: false });
            const from = this.$dayjs(event[0]);
            const to = this.$dayjs(event[1]);
            query.from = from.unix();
            query.to = to.unix();

            this.$router.replace({
                query,
            }).catch(() => {});

            this.$emit('change');
        },

        emitEvent(picker, month) {
            const query = queryString.parse(window.location.search, { sort: false });
            const from = this.$dayjs().month(month).startOf('month');
            const to = this.$dayjs().month(month).endOf('month');
            query.from = from.unix();
            query.to = to.unix();

            this.$router.replace({
                query,
            }).catch(() => {});

            this.$emit('change');

            picker.$emit('pick', [
                from,
                to,
            ]);
        },

        previousMonth() {
            this.$wait.start('loading_date_range.prev');
            const query = queryString.parse(window.location.search, { sort: false });
            const from = this.$dayjs(this.dateRange[0]).subtract(1, 'month').startOf('month');
            const to = this.$dayjs(this.dateRange[1]).subtract(1, 'month').endOf('month');

            this.dateRange = [
                from,
                to,
            ];

            query.from = from.unix();
            query.to = to.unix();

            this.$router.replace({
                query,
            }).catch(() => {});

            this.$emit('change');
        },

        nextMonth() {
            this.$wait.start('loading_date_range.next');
            const query = queryString.parse(window.location.search, { sort: false });
            const from = this.$dayjs(this.dateRange[0]).add(1, 'month').startOf('month');
            const to = this.$dayjs(this.dateRange[1]).add(1, 'month').endOf('month');

            this.dateRange = [
                from,
                to,
            ];

            query.from = from.unix();
            query.to = to.unix();

            this.$router.replace({
                query,
            }).catch(() => {});

            this.$emit('change');
        },
    },
};
</script>

<style>
.date-range-picker .el-date-editor {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 240px !important;
    justify-content: flex-end !important;
}
.date-range-picker .el-range__close-icon {
    display: none !important;
}
.date-range-picker .el-picker-panel__shortcut {
    line-height: 26px;
}
</style>
<style scoped>
    .btn {
        background-color: #409EFF;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;
        transition: 250ms ease all;

    }
    .btn:hover {
        background-color: #5badff;
    }

    .btn svg{
        margin: 0;
    }

    .btn-left {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
</style>
